import { Grid, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import Stack from "@mui/material/Stack";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import UpdateIcon from "@mui/icons-material/Update";

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    height: "10%",
    backgroundImage:
      "linear-gradient(to right, #8f8f8c 30%,#5370d0 70%,  #8f8f8c 100%)",
  },
}));
export default function Footer() {
  const d = new Date();
  const year = d.getFullYear();
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingBottom: "2%",
        }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Typography
            sx={{ typography: { xs: "h3w", sm: "h3w", md: "h3w" } }}
            style={{ paddingTop: "10%" }}
          >
            Get In Touch{" "}
          </Typography>
          <Stack direction="row" alignItems="center" gap={1}>
            <EmailIcon
              style={{
                color: "white",
                fontSize: 30,
              }}
            />
            <Typography
              sx={{ typography: { xs: "h5w", sm: "h5w", md: "h5w" } }}
            >
              <a
                href="mailto:admin@aussielead.com"
                style={{
                  color: "#000000",
                }}
              >
                admin@aussielead.com
              </a>
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" gap={1}>
            <CallIcon
              style={{
                color: "white",
                fontSize: 30,
              }}
            />
            <Typography
              sx={{ typography: { xs: "h5w", sm: "h5w", md: "h5w" } }}
            >
              Australia:{" "}
              <a
                href="tel:+61422658838"
                style={{
                  color: "#000000",
                }}
              >
                +61 422 658 838
              </a>
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" gap={1}>
            <UpdateIcon
              style={{
                color: "white",
                fontSize: 30,
              }}
            />
            <Typography
              sx={{ typography: { xs: "h5w", sm: "h5w", md: "h5w" } }}
            >
              9:00am – 5.30pm (Monday to Friday)
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <LocationOnIcon
              style={{
                color: "white",
                fontSize: 30,
              }}
            />
            <Typography
              sx={{ typography: { xs: "h5w", sm: "h5w", md: "h5w" } }}
            >
              The Hills District, Sydney Australia
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Link href="/privacy-policy">
            <Typography
              sx={[
                { typography: { xs: "h5w", sm: "h5w", md: "h5w" } },
                { textDecoration: "underline" },
              ]}
              display="inline"
            >
              PRIVACY POLICY
            </Typography>
          </Link>

          <Typography sx={{ typography: { xs: "h5w", sm: "h5w", md: "h5w" } }}>
            Copyright © {year} - Aussielead Pty Ltd
          </Typography>
          <Typography sx={{ typography: { xs: "h5w", sm: "h5w", md: "h5w" } }}>
            All Rights Reserved
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}
