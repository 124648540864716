import React from "react";
import Typography from "@mui/material/Typography";
import { Button, Card, CardContent, Grid } from "@mui/material";
import TextScroller from "./TextScroller";
import Box from "@mui/material/Box";
import palette from "../theme/palette";
import ContactButton from "./ContactButton";

const consultingServices = [
  {
    category: "IT Strategy Development",
    description:
      "We work closely with businesses to understand their objectives and align their IT strategy with their overall business strategy. We help develop a roadmap that outlines the technology initiatives needed to support their growth and drive competitive advantage.",
  },
  {
    category: "Technology Implementation",
    description:
      "We assist businesses in implementing new technologies and systems. This includes software and hardware selection, customization, configuration, integration, and deployment. We ensure a smooth transition and provide support during the implementation process.",
  },
  {
    category: "IT Support and Maintenance",
    description:
      "We provide ongoing technical support and maintenance services to keep systems running smoothly. This includes troubleshooting, system upgrades, and proactive monitoring to identify and resolve issues before they impact business operations",
  },
];

const consultingBenefits = [
  {
    label: "Strategic Alignment",
    description:
      "We help organizations align their technology investments with their overall business strategy through our professional technology consulting team. This greatly helps businesses to identify and implement the right technologies that support their long-term goals, improve operational efficiency, and gain a competitive edge in the market.",
  },
  {
    label: "Expertise and Knowledge",
    description:
      "We bring specialized expertise and knowledge in various domains, such as cloud computing, cyber security, data analytics, artificial intelligence, and digital transformation. Clients stay updated with the latest industry trends, emerging technologies, and best practices, allowing businesses to leverage their insights and make informed decisions.",
  },
  {
    label: "Problem Solving and Innovation",
    description:
      "We excel in problem-solving and identifying opportunities for innovation. We assess existing technology infrastructure, identify pain points and bottlenecks, and propose innovative solutions to address them. By leveraging our expertise, we can help organizations overcome challenges and unlock new possibilities for growth and improvement.",
  },
];

export default function TechConsulting(): JSX.Element {
  return (
    <div>
      <Grid
        style={{
          paddingTop: "5%",
        }}
      >
        <TextScroller text="Technology Consulting" fontSize="3rem" />
        <Grid
          style={{
            paddingTop: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ typography: { xs: "h4b", sm: "h4b", md: "h3" } }}
            style={{
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            We offer technology consulting service which is utmost importance
            for organizations to navigate the complex and rapidly changing
            technology landscape. It enables businesses to make informed
            decisions, optimize their technology investments, mitigate risks,
            drive innovation, and ultimately achieve their business objectives
            in a digital-driven world.
          </Typography>
        </Grid>
        <Typography
          sx={{ typography: { xs: "h3s", sm: "h3s", md: "h3" } }}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            paddingTop: "12%",
          }}
        >
          Benefits of Consulting
        </Typography>

        <Grid
          container
          spacing={5}
          justifyContent="center"
          style={{
            paddingTop: "3%",
            paddingLeft: "15%",
            paddingRight: "15%",
          }}
        >
          {consultingBenefits.map((benefit) => (
            <Grid item xs={12} sm={6} md={4} pr={1} key={benefit.label}>
              <Card
                sx={{
                  height: "100%",
                  border: 1,
                  borderColor: palette.text.title,
                  ":hover": {
                    boxShadow: 20,
                  },
                }}
              >
                <CardContent>
                  <Box textAlign="center">
                    <Typography variant="h4" gutterBottom>
                      {benefit.label}
                    </Typography>
                  </Box>
                  <Typography>{benefit.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Typography
          sx={{ typography: { xs: "h3s", sm: "h3s", md: "h3" } }}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            paddingTop: "12%",
          }}
        >
          Key Consulting Services
        </Typography>
      </Grid>
      <Grid
        container
        spacing={5}
        justifyContent="center"
        style={{
          paddingTop: "3%",
          paddingLeft: "15%",
          paddingRight: "15%",
        }}
      >
        {consultingServices.map((ele) => (
          <Grid item xs={12} sm={6} md={4} pr={1} key={ele.category}>
            <Card
              sx={{
                height: "100%",
                border: 1,
                borderColor: palette.text.title,
                ":hover": {
                  boxShadow: 20,
                },
              }}
            >
              <CardContent>
                <Box textAlign="center">
                  <Typography variant="h4" gutterBottom>
                    {ele.category}
                  </Typography>
                </Box>
                <Typography>{ele.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <ContactButton />
      </Grid>
    </div>
  );
}
