import {
  Button,
  CardActions,
  Divider,
  Grid,
  LinearProgress,
} from "@mui/material";
import TextScroller from "./TextScroller";
import "../App.css";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import RocketIcon from "@mui/icons-material/Rocket";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HttpsIcon from "@mui/icons-material/Https";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import AppsIcon from "@mui/icons-material/Apps";
import Box from "@mui/material/Box";
import GroupsIcon from "@mui/icons-material/Groups";
import palette from "../theme/palette";
import Contact from "./Contact";
import { TypeAnimation } from "react-type-animation";

import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import EngineeringIcon from "@mui/icons-material/Engineering";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import LinearWithValueLabel from "./LinearWithValueLabel";

const statusIcon = (status: String, color: string) => {
  const upperStatus = status.toUpperCase();
  const fontSize = 100;
  switch (upperStatus) {
    case "SECURITY":
      return (
        <HttpsIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
    case "PRODUCTIVITY":
      return (
        <RocketIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
    case "RESILIENCE":
      return (
        <SportsGymnasticsIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
    case "COST":
      return (
        <MonetizationOnIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
    case "CLOUD":
      return (
        <CloudSyncIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
    case "APPLICATION":
      return (
        <AppsIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
    case "CONSULTING":
      return (
        <GroupsIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
    default:
      return (
        <SportsGymnasticsIcon
          style={{
            color: color,
            fontSize: fontSize,
          }}
        />
      );
  }
};

export default function Home() {
  const services = [
    {
      id: 1,
      href: "/cloud",
      iconLabel: "Cloud",
      iconColor: "#ee8a4c",
      content: "Cloud Services",
      description:
        "Aussielead delivers cutting-edge cloud services to fuel companies' success by offering scalable, reliable, and cost-effective cloud services tailored to clients' unique needs",
    },
    {
      id: 2,
      href: "/app-development",
      iconLabel: "Application",
      iconColor: "#20aab6",
      content: "Application Development",
      description:
        "With our expertise in IT application development, we create tailor-made applications that revolutionize the way enterprises operate, collaborate, and serve customers",
    },
    // {
    //   id: 3,
    //   href: "/consulting",
    //   iconLabel: "Consulting",
    //   iconColor: "#b0a784",
    //   content: "Consulting",
    //   description:
    //     "We collaborate with companies to navigate the complex technology landscape, make informed decisions, and leverage the full potential of technology to achieve their business objectives",
    // },
  ];

  const goals = [
    {
      id: 1,
      src: "https://pub-expose.s3.ap-southeast-2.amazonaws.com/web/data-security.png",
      content: "Protect Data Security",
      iconLabel: "Security",
      iconColor: "#4164d7",
      description:
        "Data is the core asset of any company and our topmost concern",
    },
    {
      id: 2,
      src: "https://pub-expose.s3.ap-southeast-2.amazonaws.com/web/productivity.png",
      content: "Boost Productivity",
      iconLabel: "Productivity",
      iconColor: "#e53555",
      description:
        "Productivity is vital for companies to thrive in a rapidly evolving landscape",
    },
    {
      id: 3,
      src: "https://pub-expose.s3.ap-southeast-2.amazonaws.com/web/consultancy.png",
      content: "Accomplish Resilience",
      iconLabel: "Resilience",
      iconColor: "#3cbe46",
      description:
        "Resilience is essential for ensuring business continuity and protecting critical data",
    },
    {
      id: 4,
      src: "https://pub-expose.s3.ap-southeast-2.amazonaws.com/web/cost.png",
      content: "Grow Revenue",
      iconLabel: "Cost",
      iconColor: "#ee8a4c",
      description: "",
    },
  ];

  const stories = [
    {
      id: 1,
      industry: "Medical Industry",
      content:
        "Design and deploy a service for hospitals to immediately send R12 ECG reports of AI identified critical ST-Elevation Myocardial Infarction(STEMI) patients to doctors via Email/SMS. With this service, medical professionals can promptly diagnose and initiate life-saving interventions for patients experiencing STEMI.",
    },
    {
      id: 2,
      industry: "Fin Tech Industry",
      content:
        "Deploy the whole email service for client to meet strict marketing and transactional email data governance requirement, offering powerful features like user email activity tracking, custom branding, batch sending, and templated emails. This is a prerequisite for our client to integrate with big brands' IT portal",
    },
    {
      id: 3,
      industry: "Finance Industry",
      content:
        "Build a highly secure and scalable FTP service for client's customers to upload/download sensitive finance documents on AWS S3. This service includes public key authentication, directory mapping, IP whitelisting as well as auditing and logging of user activities",
    },
    {
      id: 4,
      industry: "Construction Industry",
      content:
        "Migration of client's IT cloud infrastructure to reduce cost up to 70% and also greatly enhance scalability, improved performance, better security, and increase business agility because of our remarkable level of expertise and exceptional performance",
    },
  ];

  return (
    <>
      {/*<div className={'container'}>*/}
      {/*  <Grid*/}
      {/*    style={{*/}
      {/*      paddingTop: "8%",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <TextScroller*/}
      {/*      text="Thrive your business with faster release of groundbreaking products at lower IT cost"*/}
      {/*      fontSize="h2"*/}
      {/*    />*/}
      {/*    <Typography*/}
      {/*      sx={{ typography: { xs: "h4b", sm: "h4", md: "h3" } }}*/}
      {/*      style={{*/}
      {/*        paddingTop: "8%",*/}
      {/*        textAlign: "center",*/}
      {/*      }}*/}
      {/*    >*/}

      {/*        Time is <span className="fade-in">precious</span>*/}
      {/*        <span>*/}
      {/*          {" "}*/}
      {/*          and <span className="fade-in">limited</span> for innovative*/}
      {/*          products*/}
      {/*        </span>*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}
      {/*</div>*/}
      <Box
        style={{
          paddingTop: "5%",
        }}
        textAlign={"center"}
      >
        <Typography
          sx={{ typography: { xs: "h2", sm: "h2", md: "h1" } }}
          style={{
            color: "black",
            lineHeight: "4rem",
          }}
        >
          Thrive Business
        </Typography>
        <TypeAnimation
          sequence={[
            "with faster release of groundbreaking products at LOWER IT cost",
            1000,
            "with decades of professional IT expertise",
            1000,
            "with customer-centric approach",
            1000,
          ]}
          wrapper="span"
          speed={10}
          deletionSpeed={80}
          cursor={true}
          style={{
            fontSize: "2em",
            display: "inline-block",
            color: palette.text.title,
          }}
          repeat={Infinity}
        />
      </Box>

      <Divider
        style={{
          paddingTop: "5%",
        }}
      />
      <Typography
        sx={{ typography: { xs: "h2s", sm: "h2s", md: "h2" } }}
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          paddingTop: "5%",
        }}
      >
        Primary Services
      </Typography>

      <Grid
        container
        spacing={5}
        justifyContent="center"
        style={{
          paddingTop: "3%",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        {services.map((service) => (
          <Grid item xs={12} sm={6} md={4} pr={1} key={service.id}>
            <Card
              sx={{
                height: "100%",
                border: 1,
                borderColor: palette.text.title,
                ":hover": {
                  boxShadow: 20,
                },
              }}
            >
              <Box textAlign="center">
                {statusIcon(service.iconLabel, service.iconColor)}
              </Box>
              <CardContent>
                <Box textAlign="center">
                  <Typography variant="h4" gutterBottom>
                    {service.content}
                  </Typography>
                </Box>
                <Typography>{service.description}</Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  href={service.href}
                  color={"inherit"}
                  sx={{
                    color: palette.text.title,
                  }}
                >
                  Read More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box
        style={{
          paddingTop: "5%",
        }}
        textAlign={"center"}
      >
        <TypeAnimation
          sequence={[
            "We never over-promise",
            1000,
            "We never under-deliver",
            1000,
          ]}
          wrapper="span"
          speed={20}
          deletionSpeed={80}
          cursor={true}
          style={{
            fontSize: "2em",
            display: "inline-block",
            color: palette.text.title,
          }}
          repeat={Infinity}
        />
      </Box>
      <Divider
        style={{
          paddingTop: "5%",
        }}
      />
      <Typography
        sx={{ typography: { xs: "h2s", sm: "h2s", md: "h2" } }}
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          paddingTop: "5%",
        }}
      >
        Customer Successes
      </Typography>

      <Grid
        container
        spacing={5}
        justifyContent="center"
        style={{
          paddingTop: "3%",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        {stories.map((story) => (
          <Grid item xs={12} sm={6} md={3} pr={1} key={story.id}>
            <Card
              sx={{
                height: "100%",
                border: 1,
                borderColor: palette.text.title,
                ":hover": {
                  boxShadow: 20,
                },
              }}
            >
              {/*<Box textAlign="center">*/}
              {/*  {statusIcon(goal.iconLabel, goal.iconColor)}*/}
              {/*</Box>*/}
              <CardContent>
                <Box textAlign="center">
                  <Typography variant="h4" gutterBottom>
                    {story.industry}
                  </Typography>
                </Box>
                <Typography>{story.content}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/*<Divider*/}
      {/*  style={{*/}
      {/*    paddingTop: "5%",*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<Typography*/}
      {/*  sx={{ typography: { xs: "h2s", sm: "h2s", md: "h2" } }}*/}
      {/*  style={{*/}
      {/*    display: "flex",*/}
      {/*    justifyContent: "space-evenly",*/}
      {/*    alignItems: "center",*/}
      {/*    paddingTop: "5%",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Core Capabilities*/}
      {/*</Typography>*/}
      {/*<Grid*/}
      {/*  container*/}
      {/*  spacing={5}*/}
      {/*  justifyContent="center"*/}
      {/*  style={{*/}
      {/*    paddingTop: "3%",*/}
      {/*    paddingLeft: "5%",*/}
      {/*    paddingRight: "5%",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {goals.map((goal) => (*/}
      {/*    <Grid item xs={12} sm={6} md={3} pr={1} key={goal.id}>*/}
      {/*      <Card sx={{height: "100%",border: 1, borderColor: palette.text.title, ':hover': {*/}
      {/*          boxShadow: 20,*/}
      {/*        }}}>*/}
      {/*        <Box textAlign="center">*/}
      {/*          {statusIcon(goal.iconLabel, goal.iconColor)}*/}
      {/*        </Box>*/}
      {/*        <CardContent>*/}
      {/*          <Box textAlign="center">*/}
      {/*            <Typography variant="h4" gutterBottom>*/}
      {/*              {goal.content}*/}
      {/*            </Typography>*/}
      {/*          </Box>*/}
      {/*          <Typography>{goal.description}</Typography>*/}
      {/*        </CardContent>*/}
      {/*      </Card>*/}
      {/*    </Grid>*/}
      {/*  ))}*/}
      {/*</Grid>*/}

      <LinearWithValueLabel />

      <Divider
        style={{
          paddingTop: "5%",
        }}
      />
      <Contact />
    </>
  );
}
