import React from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";

export default function EcgSTEMIAutoReport(): JSX.Element {
  return (
    <div>
      <Grid
        style={{
          paddingTop: "5%",
        }}
      >
        <Typography
          sx={{ typography: { xs: "h4", sm: "h2", md: "h2" } }}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          STEMI Patients Auto Report
        </Typography>
        <Grid
          style={{
            paddingTop: "5%",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <Typography
            sx={{ typography: { xs: "h4r", sm: "h5", md: "h3r" } }}
            style={{
              paddingTop: "3%",
            }}
          >
            <b>Background: </b>
          </Typography>
          <Typography
            display={"inline"}
            sx={{ typography: { xs: "h5", sm: "h5", md: "h4b" } }}
            style={{
              paddingTop: "2%",
            }}
          >
            Notifying doctors promptly about STEMI patients is crucial for
            initiating time-sensitive treatments, minimizing heart muscle
            damage, improving patient outcomes, coordinating specialized care,
            and identifying high-risk features. Early intervention can save
            lives and enhance the chances of a successful recovery.
            <br />
          </Typography>
          <Typography
            sx={{ typography: { xs: "h4r", sm: "h5", md: "h3r" } }}
            style={{
              paddingTop: "3%",
            }}
          >
            <b>Target: </b>
          </Typography>
          <Typography
            display={"inline"}
            sx={{ typography: { xs: "h5", sm: "h5", md: "h4b" } }}
            style={{
              paddingTop: "2%",
              paddingRight: "10%",
            }}
          >
            Send critical STEMI patients' report to doctors within{" "}
            <span className={"latest"}>15 seconds</span> after ECG is taken
            <br />
          </Typography>

          <Typography
            sx={{ typography: { xs: "h4r", sm: "h5", md: "h3r" } }}
            style={{
              paddingTop: "3%",
            }}
          >
            <b>
              Solution: <br />
            </b>
          </Typography>
          <Typography
            sx={{ typography: { xs: "h5", sm: "h5", md: "h4b" } }}
            display={"inline"}
          >
            Step 1: Read each generated ECG xml and filter the ones based on
            hospital-defined critical statements
          </Typography>
          <Typography sx={{ typography: { xs: "h5", sm: "h5", md: "h4b" } }}>
            Step 2: Send critical statement as TEXT message to designated
            doctor's mobile number
          </Typography>
          <Typography sx={{ typography: { xs: "h5", sm: "h5", md: "h4b" } }}>
            {" "}
            Step 3: Polish the ECG PDF to add more additional fields and send it
            to designated doctor's email{" "}
          </Typography>
          <Typography sx={{ typography: { xs: "h5", sm: "h5", md: "h4b" } }}>
            Step 4: More additional services such as auditing, weekly/monthly
            report
          </Typography>
        </Grid>
        <Box
          textAlign="center"
          style={{
            paddingTop: "8%",
            paddingBottom: "10%",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              justifyContent: "center",
            }}
            href={"/contact"}
          >
            Contact Us
          </Button>
        </Box>
      </Grid>
    </div>
  );
}

//
// Notifying doctors of ST-Elevation Myocardial Infarction (STEMI) patients quickly is of utmost importance due to several critical reasons:
//
//   Time-sensitive treatment: STEMI is a severe form of heart attack caused by a complete blockage of a coronary artery. Rapid medical intervention is crucial to restore blood flow to the heart muscle and minimize damage. The recommended treatment for STEMI is percutaneous coronary intervention (PCI) or thrombolytic therapy, both of which are time-sensitive procedures. Prompt notification allows doctors to initiate these treatments as early as possible, increasing the chances of a successful outcome and reducing the risk of complications or death.
//
//   Preservation of heart muscle: During a STEMI, the heart muscle is deprived of oxygen and nutrients due to the blocked artery. The longer the delay in restoring blood flow, the greater the extent of damage to the heart muscle. Early notification enables doctors to expedite the process of reperfusion therapy, which can help limit the size of the heart attack and preserve more healthy heart tissue.
//
//   Improved patient outcomes: Studies have consistently shown that early intervention and treatment significantly improve outcomes for STEMI patients. Prompt reperfusion therapy has been associated with reduced mortality rates, decreased risk of heart failure, and improved long-term prognosis. Timely notification allows doctors to mobilize the necessary resources, assemble the cardiac catheterization team, and initiate the appropriate treatment promptly, leading to better patient outcomes.
//
//   Coordination of specialized care: STEMI patients require specialized care involving a multidisciplinary team, including interventional cardiologists, nurses, and support staff. Notifying doctors promptly ensures that the necessary personnel and resources are mobilized efficiently, reducing delays and optimizing patient care. This coordination is crucial for achieving door-to-balloon (D2B) time targets, which aim to minimize the time from a patient's arrival at the hospital to the initiation of PCI.
//
// Identification of high-risk patients: Notifying doctors quickly allows them to assess the patient's condition promptly and identify high-risk features that may require additional interventions or close monitoring. Early notification facilitates the evaluation of the patient's clinical status, review of medical history, interpretation of electrocardiograms, and identification of any contraindications to specific therapies. This information enables doctors to make informed decisions and tailor treatment plans accordingly.
//
//   In summary, notifying doctors promptly about STEMI patients is crucial for initiating time-sensitive treatments, minimizing heart muscle damage, improving patient outcomes, coordinating specialized care, and identifying high-risk features. Early intervention can save lives and enhance the chances of a successful recovery.
