import { Button } from "@mui/material";
import React from "react";
import palette from "../theme/palette";
import Box from "@mui/material/Box";

export default function ContactButton(): JSX.Element {
  return (
    <Box
      textAlign="center"
      style={{
        paddingTop: "8%",
        paddingBottom: "10%",
      }}
    >
      <Button
        variant="outlined"
        color={"inherit"}
        sx={{
          color: palette.text.title,
        }}
        href={"/contact"}
      >
        Contact Us
      </Button>
    </Box>
  );
}
