import React from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import TextScroller from "./TextScroller";
import "../App.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import palette from "../theme/palette";
import ContactButton from "./ContactButton";

const cloudBenefits = [
  {
    content: "Scalability and Flexibility",
    description:
      "Cloud services offer on-demand scalability, allowing organizations to adapt quickly and efficiently",
  },
  {
    content: "Cost Efficiency",
    description:
      "Attractive to startups and small businesses with limited budgets with a pay-as-you-go model",
  },
  {
    content: "Accessibility and Collaboration",
    description:
      "Enable remote work, facilitate collaboration among geographically dispersed teams, and enhance productivity",
  },
  {
    content: "Data Security and Reliability",
    description:
      "Storing data on the cloud ensures protection against physical damage or loss that could occur with on-premises infrastructure",
  },
  {
    content: "Innovation and Agility",
    description:
      "Enable businesses to harness their potential and stay ahead of the competition",
  },
  {
    content: "Business Continuity",
    description:
      "With data stored in the cloud, organizations can quickly recover and resume operations, minimizing downtime and potential revenue loss.",
  },
];

export default function AWSCloud(): JSX.Element {
  return (
    <div>
      <Grid
        style={{
          paddingTop: "5%",
        }}
      >
        <TextScroller text="Cloud Services" fontSize="3rem" />
        <Grid
          style={{
            paddingTop: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ typography: { xs: "h4b", sm: "h4b", md: "h3" } }}
            style={{
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Cloud Services have become an essential component of modern business
            operations, offering cost efficiency, flexibility, security, and the
            foundation for innovation. Embracing cloud technology empowers
            organizations to enhance their efficiency, agility, and
            competitiveness in an increasingly digital world.
          </Typography>
        </Grid>

        <Typography
          sx={{ typography: { xs: "h3s", sm: "h3s", md: "h3" } }}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            paddingTop: "12%",
          }}
        >
          Benefits of Cloud Services
        </Typography>
        <Grid
          container
          spacing={5}
          style={{
            paddingTop: "3%",
            paddingLeft: "15%",
            paddingRight: "15%",
          }}
        >
          {cloudBenefits.map((benefit) => (
            <Grid item xs={12} sm={6} md={4} pr={1} key={benefit.content}>
              <Card
                sx={{
                  height: "100%",
                  border: 1,
                  borderColor: palette.text.title,
                  ":hover": {
                    boxShadow: 20,
                  },
                }}
              >
                <CardContent>
                  <Box textAlign="center">
                    <Typography variant="h4" gutterBottom>
                      {benefit.content}
                    </Typography>
                  </Box>
                  <Typography>{benefit.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Grid
          style={{
            paddingTop: "12%",
          }}
        >
          <TextScroller text="AWS(Amazon Web Services)" fontSize="3rem" />
          <Typography
            sx={{ typography: { xs: "h4b", sm: "h4b", md: "h3" } }}
            style={{
              paddingTop: "2%",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            AWS is crucial in today's digital landscape due to its comprehensive
            services, scalability, security, cost efficiency, global
            infrastructure, and support for innovation. It empowers businesses
            to build, deploy, and scale applications quickly and securely,
            enabling them to stay competitive, reduce time-to-market, and drive
            digital transformation.
          </Typography>
          <Box textAlign={"center"}>
            <Typography
              sx={{ typography: { xs: "h4b", sm: "h4b", md: "h3" } }}
              style={{
                paddingTop: "5%",
              }}
            >
              We are <span className={"latest"}>PROFICIENT</span> at AWS
            </Typography>
          </Box>
        </Grid>
        <ContactButton />
      </Grid>
    </div>
  );
}
