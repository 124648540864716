import React from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { animated, useSpring } from "react-spring";
import Box from "@mui/material/Box";
import palette from "../theme/palette";
import ContactButton from "./ContactButton";

const useStyles = makeStyles((theme: Theme) => ({
  formStyle: {
    marginLeft: "10%",
    marginRight: "10%",
  },
}));

function Number({ n }: { n: number }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 200,
    config: { mass: 1, tension: 20, friction: 20 },
  });
  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}

const numberStyles = {
  height: "100px",
  width: "100px",
  backgroundColor: "#006088",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "1.5rem",
  fontSize: "24px",
  color: "white",
};
export default function AboutUs(): JSX.Element {
  const classes = useStyles();
  const numbers = [
    {
      n: 18,
      text: "years experience",
    },
    {
      n: 6,
      text: "customers",
    },
    // {
    //   n: 26,
    //   text: "projects",
    // },
    {
      n: 100,
      showPercentage: true,
      text: "Australia Owned",
    },
  ];
  return (
    <div>
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        style={{
          paddingTop: "5%",
          paddingBottom: "5%",
        }}
      >
        <Typography
          sx={{ typography: { xs: "h2", sm: "h2", md: "h1" } }}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            paddingTop: "2%",
          }}
        >
          About Aussielead
        </Typography>
      </Grid>
      <Grid
        style={{
          paddingTop: "2%",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <Typography
          display="block"
          sx={{ typography: { xs: "h6", sm: "h5", md: "h4b" } }}
        >
          Aussielead is a leading IT company specializing in AWS Cloud services,
          application development, and technology consulting. With a deep
          passion for innovation and a commitment to excellence, we help
          businesses harness the power of cutting-edge technologies to drive
          their digital transformation.
        </Typography>
        {/*<Grid*/}
        {/*  style={{*/}
        {/*    display: "flex",*/}
        {/*    justifyContent: "space-evenly",*/}
        {/*    alignItems: "center",*/}
        {/*    padding: "10rem 2.5rem 2rem 2rem",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {numbers.map((i, index) => (*/}
        {/*    <div*/}
        {/*      key={index}*/}
        {/*      style={{*/}
        {/*        display: "flex",*/}
        {/*        flexDirection: "column",*/}
        {/*        justifyContent: "center",*/}
        {/*        alignItems: "center",*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <div style={numberStyles}>*/}
        {/*        <Number n={i.n} />*/}
        {/*        {i.showPercentage && "%"}*/}
        {/*      </div>*/}
        {/*      <div>{i.text}</div>*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</Grid>*/}

        <Typography
          sx={{ typography: { xs: "h3s", sm: "h3s", md: "h3" } }}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            paddingTop: "10%",
          }}
        >
          Why Choose Us
        </Typography>
        <Typography
          display="block"
          sx={{ typography: { xs: "h6", sm: "h5", md: "h4b" } }}
        >
          <span className={"fade-in"}> Expertise and Experience:</span> Our team
          comprises highly skilled professionals with a wealth of experience in
          cloud computing, application development, and technology consulting.
          We stay up to date with the latest industry trends and best practices
          to deliver innovative solutions that meet our clients' evolving needs.
        </Typography>
        <Typography
          display="block"
          sx={{ typography: { xs: "h6", sm: "h5", md: "h4b" } }}
        >
          <span className={"fade-in"}>Customer-Centric Approach:</span> We
          believe in building strong partnerships with our clients, placing
          their goals and objectives at the forefront of everything we do. We
          collaborate closely with stakeholders, ensuring transparent
          communication, and delivering solutions that align with their vision.
        </Typography>
        <Typography
          display="block"
          sx={{ typography: { xs: "h6", sm: "h5", md: "h4b" } }}
        >
          <span className={"fade-in"}>Quality and Reliability:</span>We are
          dedicated to delivering exceptional quality in every project we
          undertake. From project inception to post-launch support, we maintain
          rigorous standards to ensure reliability, performance, and security.
        </Typography>
        <Typography
          display="block"
          sx={{ typography: { xs: "h6", sm: "h5", md: "h4b" } }}
        >
          <span className={"fade-in"}>Agility and Flexibility:</span> As a
          dynamic and agile company, we adapt quickly to changing requirements
          and market dynamics. We prioritize flexibility and scalability,
          enabling our clients to respond swiftly to evolving business needs and
          seize opportunities.
        </Typography>
        <Typography
          display="block"
          sx={{ typography: { xs: "h6", sm: "h5", md: "h4b" } }}
        >
          <span className={"fade-in"}>Client Success:</span> We measure our
          success by the success of our clients. We are committed to delivering
          tangible business outcomes, helping our clients achieve their
          strategic goals, drive innovation, and gain a competitive edge in the
          digital era.
        </Typography>

        <Typography
          display="block"
          sx={{ typography: { xs: "h6", sm: "h5", md: "h4b" } }}
          style={{
            paddingTop: "5%",
          }}
        >
          At Aussielead, we are passionate about empowering businesses with
          transformative technology solutions. Partner with us to unlock your
          organization's full potential and embark on a journey of digital
          excellence. Contact us today to learn how we can assist you in your
          technology endeavors.
        </Typography>
        <ContactButton />
      </Grid>
      {/*<Grid>*/}
      {/*  <Typography variant="h2" sx={{ marginLeft: "50%", marginTop: "3%"}} >Our clients</Typography>*/}
      {/*  <Divider sx={{marginLeft: "49%", width: "8%", backgroundColor: palette.primary.main,  borderBottomWidth: 5 }}/>*/}
      {/*</Grid>*/}
    </div>
  );
}
