import { Button, Divider, Grid, TextField, Theme } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import makeStyles from "@mui/styles/makeStyles";
import { axiosContext } from "../lib/axios";
import ReCAPTCHA from "react-google-recaptcha";
import isEmail from "validator/lib/isEmail";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import palette from "../theme/palette";
import Box from "@mui/material/Box";

interface QuoteFormValues {
  name: string;
  email: string;
  mobile: string;

  message: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  formStyle: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    maxWidth: "100%",
    marginBottom: "10%",
  },
  divider: {
    color: palette.text.title,
  },
}));

export default function Contact(): JSX.Element {
  const captchaRef = useRef(null);

  const defaultValues = {
    name: "",
    email: "",
    mobile: "",
    message: "",
  };
  const [formId] = useState(
    "create-quote" + Math.random().toString(36).slice(2)
  );
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<QuoteFormValues>({
    defaultValues,
  });
  const classes = useStyles();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (values: QuoteFormValues) => {
      try {
        await axiosContext().post("/create-lead", {
          name: values.name,
          mobile: values.mobile,
          email: values.email,
          message: values.message,
        });
        navigate("/thank-you");
      } catch (e) {
        console.log("Error happens.");
        console.log(e);
      } finally {
      }
    },
    [navigate]
  );
  return (
    <div>
      <Box textAlign={"center"}>
        <Typography
          sx={{ typography: { xs: "h2", sm: "h2", md: "h1" } }}
          style={{ color: palette.text.title }}
        >
          Get In Touch
        </Typography>
        <Box alignContent={"center"} >
        <Divider className={classes.divider}  sx={{borderBottomWidth: 4, paddingTop: "1%", width:"10%",  color: palette.text.title, borderStyle:'dashed'}}></Divider>
        </Box>

        <Typography
          sx={{alignContent: "center", paddingLeft: "5%", paddingRight: "5%"}}
        >
          If you prefer, reach us at{" "}
          <a
            href="tel:+61422658838"
            style={{
              color: "#000000",
            }}
          >
            +61 422 658 838
          </a>
        </Typography>
        <Typography
          sx={{alignContent: "center", paddingLeft: "5%", paddingRight: "5%"}}
        >
          Or book an online meeting{" "}
          <a
            href="https://calendly.com/aussielead/30min"
            style={{
              color: palette.text.title,
            }}
          >
            here
          </a>
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} id={formId} noValidate>
        <Grid
          container
          className={classes.formStyle}
          style={{
            paddingTop: "5%",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              control={control}
              name={"name"}
              rules={{
                required: "This field is required",
                validate: (value) => {
                  if (value) return true;
                  else return "Please input your name";
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label={"Name"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ style: { color: palette.text.primary } }}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name={"mobile"}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    fullWidth
                    margin={"normal"}
                    label={"Mobile number"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ style: { color: palette.text.primary } }}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name={"email"}
              rules={{
                required: "This field is required",
                validate: (value) => {
                  if (isEmail(value)) return true;
                  else return "Please input a valid email address";
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    margin={"normal"}
                    label={"Email"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message || ""}
                    InputLabelProps={{ style: { color: palette.text.primary } }}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name={"message"}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    multiline
                    rows={10}
                    {...field}
                    fullWidth
                    margin={"normal"}
                    label={"Message"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ style: { color: palette.text.primary } }}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                  />
                );
              }}
            />

            <ReCAPTCHA
              sitekey="6LdkKMYkAAAAAK-v3Y_TR-Zbo9U4to1y8vQXiXUv"
              ref={captchaRef}
            />

            <Grid>
              <Box textAlign="center">
                <Button
                  variant="outlined"
                  color={"inherit"}
                  disabled={isSubmitting}
                  type={"submit"}
                  form={formId}
                  sx={{
                    color: palette.text.title,
                  }}
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </Box>
            </Grid>
            <Typography>
              This site is protected by reCAPTCHA and the Google
              <span
                style={{
                  color: "#c2112f",
                }}
              >
                {" "}
                <a
                  href="https://policies.google.com/privacy"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </a>
              </span>{" "}
              and{" "}
              <span
                style={{
                  color: "#c2112f",
                }}
              >
                <a
                  href="https://policies.google.com/terms"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  Terms of Service
                </a>
              </span>{" "}
              apply.
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
