import React from "react";
import Typography from "@mui/material/Typography";
import { Button, Card, CardContent, Grid } from "@mui/material";
import TextScroller from "./TextScroller";
import Box from "@mui/material/Box";
import palette from "../theme/palette";
import ContactButton from "./ContactButton";

const appServices = [
  {
    label: "Web Application Development",
    description:
      "Our team of skilled professionals specializes in utilizing cutting-edge technologies such as Serverless methodology, React, Python, Node.js, and TypeScript to develop robust and scalable software solutions that meet users' specific requirements. We are also provide customized application development of IoT devices",
  },
  {
    label: "Service Automation",
    description:
      "IT automation is essential for businesses and organizations looking to stay competitive in the digital age. It allows enterprises to optimize operations, reduce costs, enhance security, and provide more efficient and reliable services to their customers, we've created many outstanding automated services to replace manual tasks",
  },
  {
    label: "Monitor and alert",
    description:
      "Monitoring and alerting using IT technology is essential for maintaining a stable, secure, and high-performing IT environment. It empowers IT teams to be proactive, make data-driven decisions, and respond promptly to issues, ultimately improving overall operational efficiency and user satisfaction",
  },
];

export default function AppDevelopment(): JSX.Element {
  return (
    <div>
      <Grid
        style={{
          paddingTop: "5%",
        }}
      >
        <TextScroller text="Software Application Development" fontSize="3rem" />
        <Grid
          style={{
            paddingTop: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ typography: { xs: "h4b", sm: "h4b", md: "h3" } }}
            style={{
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            We provide software application services which are crucial for
            organizations seeking to develop, enhance, and maintain efficient
            and tailored software applications. These services provide
            comprehensive support throughout the application lifecycle, ensuring
            businesses have access to high-quality, reliable, and scalable
            solutions that optimize their operations and drive success in the
            digital landscape.
          </Typography>
        </Grid>
        <Typography
          sx={{ typography: { xs: "h3s", sm: "h3s", md: "h3" } }}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            paddingTop: "12%",
          }}
        >
          Key Services
        </Typography>
        <Grid
          container
          spacing={5}
          justifyContent="center"
          style={{
            paddingTop: "3%",
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          {appServices.map((ele) => (
            <Grid item xs={12} sm={6} md={3} pr={1} key={ele.label}>
              <Card
                sx={{
                  height: "100%",
                  border: 1,
                  borderColor: palette.text.title,
                  ":hover": {
                    boxShadow: 20,
                  },
                }}
              >
                <CardContent>
                  <Box textAlign="center">
                    <Typography variant="h4" gutterBottom>
                      {ele.label}
                    </Typography>
                  </Box>
                  <Typography>{ele.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <ContactButton />
      </Grid>
    </div>
  );
}
