import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import Contact from "./components/Contact";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import AWSCloud from "./components/AWSCloud";
import AboutUs from "./components/AboutUs";
import AppDevelopment from "./components/AppDevelopment";
import TechConsulting from "./components/TechConsulting";
import ThankYou from "./components/ThankYou";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";
import EcgSTEMIAutoReport from "./components/cases/EcgSTEMIAutoReport";

function App() {
  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            {/* syntax is differnent from previous version, but can find solution on github */}
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/cloud" element={<AWSCloud />} />
              <Route path="/app" element={<AppDevelopment />} />
              <Route path="/consulting" element={<TechConsulting />} />
              {/*<Route exact path="/application-development" element={<ApplicationDevelopment />} />*/}
              {/*<Route exact path="/contact" element={<Contact />} />*/}
              <Route path="/about" element={<AboutUs />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/ecg-stemi-auto-report"
                element={<EcgSTEMIAutoReport />}
              />
            </Routes>
            <Footer />
            <ScrollToTop />
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
