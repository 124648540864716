import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import TextScroller from "./TextScroller";

export default function ThankYou(): JSX.Element {
  return (
    <div>
      <Grid
        height={"80%"}
        style={{
          paddingTop: "5%",
        }}
      >
        <TextScroller text="Thank you" fontSize="3rem" />

        <Grid
          style={{
            paddingTop: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ typography: { xs: "h4b", sm: "h4b", md: "h3" } }}
            style={{
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Your message has been received, and we are thrilled to have the
            opportunity to assist you with your technology needs. Our team of
            experts is dedicated to providing comprehensive and innovative
            solutions tailored to your specific requirements.
          </Typography>

          <Typography
            sx={{ typography: { xs: "h4b", sm: "h4b", md: "h3" } }}
            style={{
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            We understand the importance of your inquiry and the potential
            impact it can have on your business. Rest assured that we are
            committed to delivering exceptional service and support. We are
            currently reviewing your request and gathering the necessary
            information to provide you with the best possible solution.
          </Typography>

          <Typography
            sx={{ typography: { xs: "h4b", sm: "h4b", md: "h3" } }}
            style={{
              paddingTop: "5%",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
            }}
          >
            One of our experienced consultants will be in touch with you within
            24 hours to discuss your project in more detail and answer any
            questions you may have.If you prefer,
            give us a ring on{" "}
            <a
              href="tel:+61422658838"
              style={{
                color: "#000000",
              }}
            >
              +61 422 658 838
            </a>{" "}
            or
            send us{" "}
            <a
              href="mailto:admin@aussielead.com"
              style={{
                color: "#B32D2A",
                textDecoration: "none",
              }}
            >
              admin@aussielead.com
            </a>{" "}
            an email.
          </Typography>
        </Grid>

        <Typography display="block" variant={"h2"}></Typography>
      </Grid>
    </div>
  );
}
