import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect, useMemo, useState } from "react";

interface Stage {
  label: string;
  value: number;
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { label: string; value: number }
) {
  return (
    <Box
      sx={{
        alignItems: "center",
        color: "red",
        paddingTop: "5%",
      }}
    >
      <Box
        sx={{ width: "100%", mr: 1, paddingLeft: "10%", paddingRight: "10%" }}
      >
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            backgroundColor: "#C0DCD3FF",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#23DE47",
            },
            height: "20px",
          }}
        />
      </Box>
      <Box textAlign="center" sx={{ minWidth: 35 }}>
        <Typography
          sx={{ typography: { xs: "h4r", sm: "h4r", md: "h4r" } }}
        >{`${props.label}`}</Typography>
      </Box>
    </Box>
  );
}

export default function LinearWithValueLabel() {
  const stages = useMemo(
    () => [
      {
        label: "Free consultation ",
        value: 5,
      },
      {
        label: "Contract signed",
        value: 20,
      },
      {
        label: "Development",
        value: 70,
      },
      {
        label: "User acceptance testing",
        value: 85,
      },
      {
        label: "Production release",
        value: 95,
      },
      {
        label: "Ongoing support and service",
        value: 100,
      },
    ],
    []
  );
  const [progress, setProgress] = useState<Stage>(stages[0]);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        stages[index].value >= 100 ? stages[0] : stages[index + 1]
      );
      setIndex(index + 1 >= stages.length ? 0 : index + 1);
    }, 2000);
    return () => {
      clearInterval(timer);
    };
  }, [index, stages]);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel label={progress.label} value={progress.value} />
    </Box>
  );
}
