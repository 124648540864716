import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Drawer } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";

import { NavLink as RouterLink } from "react-router-dom";
import palette from "../theme/palette";
import { alpha, styled } from "@mui/material/styles";
import { useState } from "react";
import "../App.css";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 120;
const navItems = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Cloud",
    href: "/cloud",
  },
  {
    label: "Application Development",
    href: "/app",
  },
  // {
  //   label: "Services",
  //   subMenu: [
  //     {
  //       label: "Cloud Platform",
  //       href: "/cloud",
  //     },
  //     {
  //       label: "Application Development",
  //       href: "/app-development",
  //     },
  //     {
  //       label: "Technology Consulting",
  //       href: "/consulting",
  //     },
  //   ],
  // },
  {
    label: "About",
    href: "/about",
  },
  {
    label: "Contact Us",
    href: "/contact",
  },
];

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(2),
    minWidth: 180,
    backgroundColor: palette.primary.main,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export default function Header(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <Box
      sx={{
        paddingTop: "10%",
        backgroundImage:
          "linear-gradient(to right, #8f8f8c 30%,#5370d0 70%,  #8f8f8c 100%)",
        height: "100%",
      }}
    >
      {/*<Typography variant="h6" sx={{ my: 2 }}>*/}
      {/*  MUI*/}
      {/*</Typography>*/}
      {/*<Divider />*/}

      {navItems.map((item) => {
        return (
          <RouterLink
            to={item.href}
            key={item.label}
            style={{
              textDecoration: "none",
            }}
          >
            <Button
              sx={{
                color: palette.primary.main,
                ":hover": {
                  //backgroundColor: palette.primary.footer,
                  color: palette.text.title,
                },
                fontSize: 18,
                fontWeight: "bold",
                width: "100%",
                textTransform: "none",
                justifyContent: "flex-start",
                paddingLeft: "20%",
              }}
              onClick={handleDrawerToggle}
            >
              {item.label}
            </Button>
          </RouterLink>
        );
      })}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          backgroundImage:
            "linear-gradient(to right, #8f8f8c 30%,#5370d0 70%,  #8f8f8c 100%)",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon
              sx={{
                color: palette.text.primary,
              }}
            />
          </IconButton>
          <Box style={{ paddingLeft: "10%" }}>
            <img
              src="https://pub-expose.s3.ap-southeast-2.amazonaws.com/web/logo-no-title.png"
              width="50"
              height="50"
              alt="Logo"
            />
          </Box>
          <Typography
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "block", sm: "block" },
              typography: { xs: "h3w", sm: "h3w", md: "h2w" },
            }}
          >
            Aussielead
          </Typography>
          {/* This Box component is for desktop display*/}
          <Box
            sx={{ display: { xs: "none", sm: "block" } }}
            style={{ paddingRight: "10%" }}
          >
            {navItems.map((item) => {
              // if (item.href) {
              return (
                <RouterLink to={item.href} key={item.label}>
                  <Button
                    sx={{
                      color: palette.primary.main,
                      ":hover": {
                        //backgroundColor: palette.primary.footer,
                        color: palette.text.title,
                      },
                      fontSize: 18,
                      fontWeight: "bold",
                      marginRight: "30px",
                      textTransform: "none",
                    }}
                  >
                    {item.label}
                  </Button>
                </RouterLink>
              );
              // } else {
              //   return (
              //     <div key={item.label} style={{ display: "inline-block" }}>
              //       {/*<Button*/}
              //       {/*  id="demo-customized-button"*/}
              //       {/*  aria-controls={open ? "demo-customized-menu" : undefined}*/}
              //       {/*  aria-haspopup="true"*/}
              //       {/*  aria-expanded={open ? "true" : undefined}*/}
              //       {/*  variant="contained"*/}
              //       {/*  disableElevation*/}
              //       {/*  onClick={handleClick}*/}
              //       {/*  endIcon={<KeyboardArrowDownIcon />}*/}
              //       {/*  key={item.label}*/}
              //       {/*  href={item.href}*/}
              //       {/*  sx={{*/}
              //       {/*    color: palette.text.secondary,*/}
              //       {/*    ":hover": {*/}
              //       {/*      backgroundColor: palette.primary.footer,*/}
              //       {/*      color: palette.text.title,*/}
              //       {/*    },*/}
              //       {/*  }}*/}
              //       {/*>*/}
              //       {/*  {item.label}*/}
              //       {/*</Button>*/}
              //
              //       {/*<StyledMenu*/}
              //       {/*  id="demo-customized-menu"*/}
              //       {/*  MenuListProps={{*/}
              //       {/*    "aria-labelledby": "demo-customized-button",*/}
              //       {/*  }}*/}
              //       {/*  anchorEl={anchorEl}*/}
              //       {/*  open={open}*/}
              //       {/*  onClose={handleClose}*/}
              //       {/*>*/}
              //       {/*  {item?.subMenu?.map((menu) => (*/}
              //       {/*    <MenuItem*/}
              //       {/*      key={menu.href}*/}
              //       {/*      component={RouterLink}*/}
              //       {/*      to={menu.href}*/}
              //       {/*      sx={[*/}
              //       {/*        { color: palette.text.link },*/}
              //       {/*        {*/}
              //       {/*          "&:hover": {*/}
              //       {/*            color: palette.text.title,*/}
              //       {/*            backgroundColor: palette.primary.footer,*/}
              //       {/*          },*/}
              //       {/*        },*/}
              //       {/*      ]}*/}
              //       {/*      onClick={handleClose}*/}
              //       {/*      disableRipple*/}
              //       {/*    ></MenuItem>*/}
              //       {/*  ))}*/}
              //       {/*</StyledMenu>*/}
              //     </div>
              //   );
              // }
            })}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              backgroundColor: palette.primary.main,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main">
        <Toolbar />
      </Box>
    </Box>
  );
}
