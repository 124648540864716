import React, { useState } from "react";
import { animated, useSpring } from "react-spring";
import Typography from "@mui/material/Typography";

const TextScroller = ({
  text,
  fontSize,
}: {
  text: string;
  fontSize: string;
}) => {
  const [key, setKey] = useState(1);

  const scrolling = useSpring({
    from: { transform: "translate(0%,0)", opacity: 0 },
    to: { transform: "translate(10%,0)", opacity: 1 },
    config: { duration: 500 },
    reset: false,
    onRest: () => {
      setKey(key + 1);
    },
  });

  return (
    <div key={key}>
      <animated.div style={scrolling}>
        <Typography
          sx={{ typography: { xs: "h3", sm: "h2", md: "h1" } }}
          style={{
            color: "black",
            lineHeight: "4rem",
            width: "80%",
            textAlign: "center",
          }}
        >
          {text}
        </Typography>
      </animated.div>
    </div>
  );
};

export default TextScroller;
