import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

export default function PrivacyPolicy(): JSX.Element {
  return (
    <div>
      <Grid
        style={{
          paddingTop: "5%",
          paddingLeft: "20%",
          paddingRight: "20%",
          paddingBottom: "10%",
        }}
      >
        <Typography sx={{ typography: { xs: "h3", sm: "h3", md: "h1" } }}>
          Privacy Policy
        </Typography>
        <Typography
          style={{
            paddingTop: "2%",
            fontWeight: "bold",
          }}
        >
          Last Updated: 8 August 2023
        </Typography>
        <Typography
          style={{
            paddingTop: "2%",
          }}
        >
          Aussielead Pty Ltd ("we," "us," or "our") is committed to protecting
          the privacy of our users ("you" or "your") and maintaining the
          confidentiality and security of the personal information you provide
          to us. This Privacy Policy outlines how we collect, use, disclose, and
          protect your personal information when you use our website, products,
          or services. By accessing or using our website or services.
        </Typography>
        <Typography
          style={{
            paddingTop: "2%",
            fontWeight: "bold",
          }}
        >
          Information We Collect
        </Typography>
        <Typography>
          We may collect the following types of information:
        </Typography>
        <Typography
          style={{
            paddingLeft: "2%",
          }}
        >
          1. Personal Information: We may collect personal information such as
          your name, email address, contact details, and any other information
          you voluntarily provide to us when you use our website or services.
        </Typography>
        <Typography
          style={{
            paddingLeft: "2%",
          }}
        >
          2. Usage Information: We may collect non-personal information about
          your interactions with our website or services, such as IP address,
          browser type, device information, referring/exit pages, and
          click-stream data. This information is used to analyze trends,
          administer our website, and improve the user experience.
        </Typography>

        <Typography
          style={{
            paddingTop: "2%",
            fontWeight: "bold",
          }}
        >
          Use of Information
        </Typography>
        <Typography
          style={{
            paddingLeft: "2%",
          }}
        >
          1. Providing Services: We use your personal information to deliver our
          products and services, respond to your inquiries, process
          transactions, and fulfill your requests. This includes providing
          technical support, troubleshooting, and customer service.
        </Typography>
        <Typography
          style={{
            paddingLeft: "2%",
          }}
        >
          2. Communication: We may use your personal information to communicate
          with you regarding updates, announcements, marketing materials, and
          promotional offers related to our products and services. You can
          opt-out of receiving such communications by following the unsubscribe
          instructions provided in the emails or by contacting us directly.
        </Typography>
        <Typography
          style={{
            paddingLeft: "2%",
          }}
        >
          3. Analytics and Improvement: We may use the collected information to
          analyze trends, monitor website usage, and gather demographic
          information. This helps us improve our website, products, and
          services, and enhance your overall experience.
        </Typography>
        <Typography
          style={{
            paddingLeft: "2%",
          }}
        >
          4. Legal Compliance: We may use or disclose your personal information
          if required by law, regulation, legal process, or government request.
          We may also use or disclose your information to enforce our terms of
          service, protect our rights or the rights of others, and investigate
          fraud or security concerns.
        </Typography>

        <Typography
          style={{
            paddingTop: "2%",
            fontWeight: "bold",
          }}
        >
          Sharing of Information
        </Typography>
        <Typography
          style={{
            paddingLeft: "2%",
          }}
        >
          1. Service Providers: We may share your personal information with
          trusted third-party service providers who assist us in delivering our
          products and services, such as hosting providers, payment processors,
          and analytics providers. These service providers are contractually
          obligated to maintain the confidentiality and security of your
          information.
        </Typography>
        <Typography
          style={{
            paddingLeft: "2%",
          }}
        >
          2. Business Transfers: In the event of a merger, acquisition, or sale
          of all or a portion of our assets, your personal information may be
          transferred as part of the transaction. We will notify you via email
          or prominent notice on our website of any change in ownership or use
          of your personal information.
        </Typography>
        <Typography
          style={{
            paddingLeft: "2%",
          }}
        >
          3. Consent: We may share your personal information with your consent
          or as otherwise disclosed at the time of collection.
        </Typography>
        <Typography
          style={{
            paddingTop: "2%",
            fontWeight: "bold",
          }}
        >
          Changes to this Privacy Policy
        </Typography>
        <Typography
          style={{
            paddingLeft: "2%",
          }}
        >
          We may update this Privacy Policy from time to time. We will notify
          you of any material changes by posting the revised policy
        </Typography>
      </Grid>
    </div>
  );
}
